import React from 'react';
import { graphql, navigate } from 'gatsby';
import SEO from '../components/seo';

class RedirectIndex extends React.PureComponent {
  constructor(args) {
    super(args);

    // Skip build, Browsers only
    if (typeof window !== 'undefined') {
      const userLang = navigator.language || navigator.userLanguage;

      if (userLang === 'fr-FR') {
        navigate('/fr/');
      } else {
        navigate('/en/');
      }
    }
  }

  render() {
    // It's recommended to add your SEO solution in here for bots
    // eg. https://github.com/ahimsayogajp/ahimsayoga-gatsby/blob/master/src/pages/index.js#L22
    return <SEO title="Furlan Snowboards - Official website and online shop" />;
  }
}

export default RedirectIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
